import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Form from "./Form"

export default function Contact() {
  const data = useStaticQuery(graphql`
    query {
      contactImg: file(relativePath: { eq: "placeholder.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const contactImg = data.contactImg.childImageSharp.fluid
  return (
    <div className="contact-container">
      <h4>Get in touch</h4>
      <h3>
        Got questions? We got answers
        <span className="period">.</span>
      </h3>
      <div className="alt">
        Not a fan of forms? Send us an{" "}
        <a href="mailto:hello@ryse.network" className="email">
          e-mail
        </a>{" "}
        instead.
      </div>
      <div className="contact-content-container">
        <div className="contact-img">
          <Img
            fluid={contactImg}
            alt="business french bulldog holding boba tea with a laptop"
          />
        </div>
        <div className="contact">
          <Form formName="contact" />
        </div>
      </div>
    </div>
  )
}
